<template>
    <div class="home-page">
      <div class="home-mobile">
        <div><img class="logo" src="../assets/logo.png" /></div>
        <h3 class="title">AI 幻颜 - AI写真、AI头像、智能换装</h3>
        <h4 class="slogan">换个装照，换个心情</h4>
      </div>
      <div class="image-container">
        <img src="../assets/1242_2208.png"/>
            <img src="../assets/1242_2208-1.png"/>
            <img src="../assets/1242_2208-2.png"/>
            <img src="../assets/1242_2208-3.png"/>
            <img src="../assets/1242_2208-4.png"/>
        </div>
        <div class="appstore">
          <a class="aaa" v-on:click="handleEvent" href="https://apps.apple.com/cn/app/ai%E5%B9%BB%E9%A2%9C-ai%E5%86%99%E7%9C%9F-%E4%B8%AA%E6%80%A7%E5%A4%B4%E5%83%8F-%E6%99%BA%E8%83%BD%E6%8D%A2%E8%A3%85/id6502613695"><img src="../assets/web_appstore.png"/></a>
          <a class="aaa" v-on:click="handleEvent" href="weixin://dl/business/?appid=wx2228c9c2813763ec&path=pages/ai_template/template"><img src="../assets/web_wechat.png"/></a>
        </div>
      <CategoryFooter />
      <a  class="gov" href="https://beian.miit.gov.cn/" target="_blank">皖ICP备2024048694号-1</a>
    </div>
  </template>
  
  <script>
  import CategoryFooter from "./common/CategoryFooter.vue";
  // import Appstore from "../common/Appstore.vue";
  
  /* eslint-disable vue/multi-word-component-names */
  export default {
    name: "HomeView",
    components: {
      CategoryFooter,
      // Appstore
    },
    created() {
      document.title = 'AI幻颜';
    }
  };
  </script>
  
  <style>
  .wxtip {
    background: rgba(0, 0, 0, 1);
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;
    img {
      width: 100%;
    }
  }

.wxtip-txt {
  margin-top: 107px;
  color: #fff;
  font-size: 20px;
  line-height: 1.8;
}
  .home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: #FFF;
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 99;
  }
  .home-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
  }
  .logo {
      width: 84px;
      margin-top: 24px !important;
  }
  .title {
    margin-top: 12px;
  }
  .slogan {
      margin-top: -8px;
  }

  .btn-download-group {
    margin: 0 auto;
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
  }
      
    img {
        width: 214px;
    }

  .appstore {
    margin-top: 24px;
    padding: 0px 8px;
    height: 56px;
    width: 375px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .appstore .aaa {
      padding: 0px;
      margin: 0px 8px;
      display: flex;
      font-size: 18px;
      line-height: 56px;
      text-align: center;
      font-weight: bold;
      height: 56px;
      width: calc(50%-24px);
      border-radius: 12px;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.8);
      background-color: transparent;
      color: #000000;

      img {
        width: 100%;
        height: 100%;
      }
  }

  .image-container {
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    /* background-color: red; */
    display: flex;
    overflow-x: scroll;
    height: 350px; /* 根据需要调整 */
    img {
      margin-right: 10px; /* 图片间隔 */
      height: auto;
      width: auto;
      border-radius: 24px;
    }
  }
  .gov {
    margin-bottom: 24px;
  }
  </style>
  