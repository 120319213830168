
import * as VueRouter from 'vue-router';
import HomeView from '../components/HomeView.vue';
import TosView from '../components/TosView.vue';
import PrivacyView from '../components/PrivacyView.vue';
import ChildrenView from '../components/ChildrenView.vue';
import ThridSDKView from '../components/ThirdSDKView.vue';
import InviteView from '../components/InviteView.vue';
import SubscribeProtocolView from '../components/SubscribeProtocolView.vue';
import SubscribeContinueProtocolView from '../components/SubscribeContinueProtocolView.vue';

const routes = [
  { path: '/', name:"Home", component: HomeView },
  { path: '/tos', name:"服务协议",  component: TosView },
  { path: '/invite', name:"分享", component: InviteView},
  { path: '/privacy', name:"隐私政策",  component: PrivacyView },
  { path: '/children_protection', name: "儿童个人信息保护政策", component: ChildrenView},
  { path: '/thirdsdk', name: "第三方信息共享服务清单", component: ThridSDKView},
  { path: '/subscribe-protocol', name: "订阅协议", component: SubscribeProtocolView},
  { path: '/renewal-protocol', name: "续订协议", component: SubscribeContinueProtocolView},
];

const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes,
  });

export default router;