<template>
    <div>
    <div v-html="htmlContent" class="content"></div>
  </div>
</template>
  
<script>
  /* eslint-disable vue/multi-word-component-names */
  export default {
    name: 'SubscribeContinueProtocolView',
    data() {
      return {
        htmlContent: ''
      };
    },
    mounted() {
      fetch('/i-words-subscribe-protocol.html')
        .then(response => response.text())
        .then(data => {
          this.htmlContent = data;
        })
        .catch(error => {
          console.error('Error loading HTML file:', error);
        });
    },
    created() {
      document.title = 'AI幻颜';
    }
  }
</script>