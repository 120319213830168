<template>
  <div class="category-footer">
    <div class="footer-container">
        
        <router-link to="/tos" class="underline">服务协议</router-link>
        <router-link to="/privacy" class="underline">隐私政策</router-link>
        <a href="mailto:magicmirror.develop@gmail.com">联系我们</a>
    </div>
    <p class="copy_right_txt">© 2024 AI幻颜</p>
  </div>
</template>


<style >
@media (min-width: 500px) {
    .category-footer{
        width: 100vw;
        margin: 0 0 30px 0;
        display: flex;
        box-shadow: inset 0px 1px 0px #EEF3F7;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        padding-top: 30px;
        .footer-container {
            width: 50%;
            display: flex;
            justify-content: center;
            align-self: center;
            a {
                font-family: Nunito;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                flex: 1;
                color: #666;
                text-align: center;
                margin: 16px;
            }
        }
        .copy_right_txt{
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 14px;
            text-align: center;
            color: rgba(0, 0, 0, 0.2485);
            margin-top: 30px;
        }
        
    }
    .underline {
        text-decoration: underline;
        color: #666;
        margin: 16px;
    }
}

@media (max-width: 500px) {
    .category-footer {
        width: 100vw;
        margin: 64px 0 30px 0;
        display: flex;
        flex-direction: column;
        /* box-shadow: inset 0px 1px 0px #EEF3F7; */
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        .footer-container {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-self: center;
            a {
                font-family: Nunito;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                margin-top: 15px;
                color: #666;
                flex: 1;
                text-align: center;
                margin: 16px;
            }
        }
        
          .copy_right_txt{
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: rgba(0, 0, 0, 0.2485);
            margin-top: 30px;
        }
    }

    .underline {
        text-decoration: underline;
        color: #666;
        margin: 16px;
    }
}
</style>
